import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  searchResultsSelector,
  searchResultsIsFetchingSelector,
  searchResultsArticlesSelector,
  searchResultsIsFetchingArticlesSelector,
  hostnameSelector,
  currencySelector,
} from '../App/selectors';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  loadSearchTermArticlesResults,
  loadSearchTermResults,
  setIsBreadcrumbVisible,
} from '../App/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';
import { SearchResults } from '../../components/Search/SearchResults';
import { addItemToCart } from '../Cart/cartSlice';
import {
  resolveBaseUrlByLang,
  resolveDomainByHostname,
} from '../../configureTrans';
import { prop } from '../../utilities';
import {
  categoryFilterDataSelector,
  urlAttribsToObject,
} from '../Category/categorySlice';
import { categoryEntitiesSelector } from '../Header/selectors';

interface Props {
  user: any;
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
      sort?: string;
      sortDir?: string;
    };
    pathname: string;
  };
  searchedProducts: any;
  searchIsFetching: boolean | true;
  dispatch: (action: any) => void;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  hostname: string;
  currency: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  byId: any;
  route: any;
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(20)};
  width: 100%;
`;

const Header = styled(FlexCol)`
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class SearchResultsContainer extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string =>
    props.location.query.q;

  public static async getInitialProps(props: Props) {
    try {
      const { dispatch, location } = props;
      const {
        query: { offset, sort, sortDir },
      } = location;

      if (props?.route?.props?.type === 'freeDelivery') {
        const filters = urlAttribsToObject(location.query);
        filters.freeDelivery = 1;

        await Promise.all([
          await dispatch(
            loadSearchTermResults(
              '',
              20,
              offset,
              undefined,
              undefined,
              undefined,
              filters,
            ),
          ),
        ]);
        dispatch(setIsBreadcrumbVisible(false));
      } else if (props?.route?.props?.type === 'news') {
        const filters = urlAttribsToObject(location.query);
        filters.new = 1;

        await Promise.all([
          await dispatch(
            loadSearchTermResults(
              '',
              20,
              offset,
              undefined,
              undefined,
              undefined,
              filters,
            ),
          ),
        ]);
        dispatch(setIsBreadcrumbVisible(false));
      } else if (props?.route?.props?.type === 'withGift') {
        const filters = urlAttribsToObject(location.query);
        filters.hasGift = 1;

        await Promise.all([
          await dispatch(
            loadSearchTermResults(
              '',
              20,
              offset,
              undefined,
              undefined,
              undefined,
              filters,
            ),
          ),
        ]);
        dispatch(setIsBreadcrumbVisible(false));
      } else {
        const searchTerm = SearchResultsContainer.getSearchQuery(props);
        await Promise.all([
          (await dispatch(loadSearchTermArticlesResults(searchTerm, 4)),
          await dispatch(
            loadSearchTermResults(
              searchTerm,
              21,
              offset,
              undefined,
              { sort: undefined },
              undefined,
              urlAttribsToObject(location.query),
            ),
          )),
        ]);
        dispatch(setIsBreadcrumbVisible(true));
      }

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const q = SearchResultsContainer.getSearchQuery(this.props);

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: `${__('Vyhľadávanie')}: ${q}` }}
            // canonicalUrl={`${resolveBaseUrlByLang(hostname, lang)}${
            //   location.pathname ? location.pathname.replace('/', '') : ''
            // }`}
          />
          {this.renderHeader()}
          {this.renderResults()}
        </Wrapper>
      </>
    );
  }

  public renderHeader() {
    const searchTerm = SearchResultsContainer.getSearchQuery(this.props);

    if (searchTerm) {
      return (
        <Header>
          <h1>
            <SearchedTermWrapper>
              {__('Hľadaný výraz')}{' '}
              <HighlitedSearchTerm>
                {' '}
                {searchTerm ? `"${searchTerm}` : ''}{' '}
              </HighlitedSearchTerm>
            </SearchedTermWrapper>
          </h1>
        </Header>
      );
    } else return <></>;
  }

  public renderResults() {
    const {
      searchedProducts: products,
      searchIsFetching,
      dispatch,
      user,
      router,
      location,
      articles,
      isFetchingArticles,
      hostname,
      lang,
      currency,
      filterData,
      byId,
      route,
    } = this.props;
    const baseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;
    const searchTerm = SearchResultsContainer.getSearchQuery(this.props);

    let title = '';
    let ignoreFilters: string[] = [];
    if (route?.props?.type === 'freeDelivery') {
      title = __('Doprava zadarmo');
      ignoreFilters.push('freeDelivery');
    } else if (route?.props?.type === 'news') {
      title = __('Novinky');
      ignoreFilters.push('new');
    } else if (route?.props?.type === 'withGift') {
      title = __('S darcekom');
      ignoreFilters.push('hasGift');
    }

    return (
      <SearchResults
        user={user}
        apiUrl={baseApiUrl}
        query={this.getPaginationQuery()}
        dispatch={dispatch}
        isFetching={searchIsFetching}
        products={products}
        addToCart={this.addToCart}
        router={router}
        searchTerm={searchTerm}
        location={location}
        articles={articles}
        isFetchingArticles={isFetchingArticles}
        domainId={+resolveDomainByHostname(hostname)}
        title={title}
        subcategoryFilterAttribs={null}
        categoryAttribs={products.attribs}
        currency={currency}
        filterData={filterData}
        lang={lang}
        byId={byId}
        ignoreFilters={ignoreFilters}
      />
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count));
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  searchIsFetching: searchResultsIsFetchingSelector(state),
  searchedProducts: searchResultsSelector(state),
  lang: langSelector(state),
  articles: searchResultsArticlesSelector(state),
  isFetchingArticles: searchResultsIsFetchingArticlesSelector(state),
  hostname: hostnameSelector(state),
  currency: currencySelector(state),
  filterData: categoryFilterDataSelector(state),
  byId: categoryEntitiesSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SearchResultsContainer' })(
    withRouter(SearchResultsContainer),
  ),
);

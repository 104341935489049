import {
  ZemplinLoaderWrapper,
  CookiesNotif,
  TopInfoBanner,
  AdminBannerActivate,
  AdminBanner,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  loadTopMenuItems,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setHostname,
  setDomainId,
  loadOtherDefaultsTexts,
} from './actions';
import { CURRENCY_COOKIE, LANG_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  langChangedSelector,
  isBreadcrumbVisibleSelector,
  ecomailSettingsSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree, loadLandingPages } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import {
  LogoMicrodata,
  SearchBoxMicrodata,
} from '@bart.sk-ecommerce/react-microdata';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from '../../theme/styled-components';
import { loadHomeProductList2, loadHomeProductList1 } from '../Home/actions';
import BreadCrumbContainer from '../BreadCrumb/BreadCrumb';
import AppLanguageRedirectChecker from '../../components/Home/AppLanguageRedirectChecker';
import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects';
import { __r } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  resolveBaseUrlByLang,
  resolveCurrencyByHostname,
  resolveDomainByHostname,
  resolveLangByHostname,
  resolveLogoByHostname,
  resolveThemeByHostname,
} from '../../configureTrans';
import { prop } from '../../utilities';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { fetchCart, resetCart } from '../Cart/cartSlice';
import { CART_COOKIE_ID, saveCartCookie } from '../Cart/helpers';
import AddToCartModalWindow from '../../components/Product/AddToCartModalWindow';
import AddToModalWindow from '../../components/Product/AddToModalWindow';
import TopInformationStrip from '../../components/Header/TopInformationStrip';
import API from '../../services/API';
import CookieConsent from '../../components/_helpers/CookieConsent/CookieConsent';

interface Props {
  user: any;
  lang: string;
  props: any;
  cookies: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  location: any;
  langChanged: boolean;
  isBreadcrumbVisible: boolean;
  hostname: string;
  otherTexts: any;
  adminContentPath: string;
  ecomailSettings: { customScript: string };
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;

class App extends React.Component<Props, { settingsLoaded: boolean }> {
  constructor(props) {
    super(props);
    this.state = { settingsLoaded: false };
  }

  public static async getInitialProps(props) {
    const { dispatch, token, user, cookies, lang } = props;

    try {
      const userCookie =
        prop(cookies, `${USER_COOKIE}`) || !isSSR()
          ? cookie.load(USER_COOKIE)
          : null;

      //let cartCookie = prop(cookies, `4eb3705d-5561-400e-b27a-25c1c6572083`);
      let cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      try {
        if (userCookie && cartCookie) {
          const tokenInfo = await API.tokeninfo({
            accessToken: userCookie,
            lang,
          });
          if (!tokenInfo || !tokenInfo.email) {
            saveCartCookie(cartCookie);
          }
        }
      } catch (e) {
        saveCartCookie(cartCookie);
      }

      await Promise.all([
        dispatch(checksRedirect(props.location)),
        dispatch(loadDefaultSettings(props.hostname)),
        dispatch(loadOtherDefaultsTexts()),
        dispatch(loadCategoriesTree()),
        dispatch(loadLandingPages()),
        dispatch(loadFooterData()),
        dispatch(loadTopMenuItems()),
        //dispatch(fetchCart(true, '4eb3705d-5561-400e-b27a-25c1c6572083', true)),
        dispatch(fetchCart(true, cartCookie, true)),
      ]);

      return {
        props,
        isLoaded: true,
        hostname: props.hostname,
      };
    } catch (exp) {
      return {
        isError: true,
        isLoaded: true,
        hostname: props.hostname,
      };
    }
  }

  componentDidUpdate(props) {
    /*
    if (
      window?.location?.hostname &&
      this.state.settingsLoaded === false &&
      props.dispatch
    ) {
      props.dispatch(loadDefaultSettings(window.location.hostname));
      this.setState({
        settingsLoaded: true,
      });
    }
    */
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      isLoaded,
      lang,
      langChanged,
      isBreadcrumbVisible,
      location,
      hostname,
      dispatch,
      otherTexts,
      adminContentPath,
      ecomailSettings,
    } = this.props;

    const themeByHostname = resolveThemeByHostname(hostname, theme);

    let isSearch = false;
    let isHome = false;
    // @ts-ignore
    if (children !== null && 'props' in children) {
      isHome = children.props.location.pathname === '/';
      isSearch = children.props.location.pathname.includes(
        __r('routes:vyhladavanie', '/vyhladavanie'),
      );
    }
    const showBreadcrumb = !isHome;

    const currentLocation = location.pathname;

    /*
    Helmet.canUseDOM = false;
    Helmet.renderStatic();
    */

    const baseUrl = resolveBaseUrlByLang(hostname, lang);

    return (
      <React.Fragment>
        {!isSSR() ? (
          <CookieConsent
            domainGroupId={domainGroupId}
            lang={lang === 'cz' ? 'Cs' : 'SK'}
          />
        ) : (
          <></>
        )}
        <GlobalStyles />
        <Helmet></Helmet>
        {baseUrl !== undefined ? (
          <>
            <LogoMicrodata
              url={baseUrl}
              logo={`${baseUrl}${resolveLogoByHostname(hostname)}`}
            />

            <SearchBoxMicrodata
              baseUrl={baseUrl}
              searchUrl={baseUrl + '/vyhladavanie?q={search_term_string}'}
            />
          </>
        ) : null}

        {ecomailSettings?.customScript ? (
          <Helmet>
            <script>{decodeURIComponent(ecomailSettings.customScript)}</script>
          </Helmet>
        ) : (
          <></>
        )}

        <ThemeProvider theme={themeByHostname}>
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                og_url: `${baseUrl}${location.pathname}`,
              }}
            />
          )}

          {this.props?.location?.search?.includes('activate-admin-banner') ? (
            <AdminBannerActivate />
          ) : (
            <></>
          )}

          <AdminBanner
            adminDomainUrl={process.env.REACT_APP_ADMIN_URL as string}
            contentPath={adminContentPath}
          />

          <AppLanguageRedirectChecker
            lang={lang}
            langChanged={langChanged}
            currentLocation={currentLocation}
          />

          {otherTexts?.TOP_INFORMATION_STRIP?.body ? (
            <TopInformationStrip
              text={otherTexts?.TOP_INFORMATION_STRIP?.body}
            ></TopInformationStrip>
          ) : (
            <></>
          )}

          <ContainerHeader isHome={isHome} />
          {isBreadcrumbVisible && showBreadcrumb && <BreadCrumbContainer />}
          <FillSpaceWrapper>
            {/*
            {!isLoaded ? <ZemplinLoaderWrapper height={300} /> : children}
            */}
            {children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} currentLocation={currentLocation} />
          <AddToModalWindow dispatch={dispatch} />
          <AddToCartModalWindow dispatch={dispatch} />
          {dimmerVisible && <Dimmer height={null} />}
          {/* {!isSSR() && <CookiesNotif />} */}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    langChanged: langChangedSelector(state),
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    isBreadcrumbVisible: isBreadcrumbVisibleSelector(state),
    otherTexts: state.general.otherTexts,
    adminContentPath: state.general.adminContentPath,
    ecomailSettings: ecomailSettingsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);

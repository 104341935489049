import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import {
  FlexCol,
  FlexRow,
  FlexRowCenterVertical,
  DsiRightUpper as RightUpper,
  getProductDetailInfo,
  TextP,
  DsiSvgIcon as SvgIcon,
  DsiDescription as Description,
  DsiSpecification as Specification,
  DsiReviews as Reviews,
  DsiRelated as Related,
  DsiRelatedCategories as RelatedCategories,
  ZemplinLoaderWrapper,
  OutsideClick,
  IconImg,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getProductMetaTags } from '../../utilities/metatags';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { useState } from 'react';
import { ProductDetailSliderComponent as SliderComponent } from '../_helpers/Slider/ProductDetailSlider';
import { Modal } from '../_helpers/Modal/Modal';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddTo } from './AddTo';
import { WatchDog } from './WatchDog';
import { FlexColCenterVertical } from 'eshop-defaults/lib';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { ProductMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { prop, round } from '../../utilities';
import { __, __r } from 'react-i18n';
import { push } from 'react-router-redux';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import {
  getOtherLang,
  resolveBaseUrlByLang,
  resolveOtherBaseUrlByLang,
} from '../../configureTrans';
import { withRouter, WithRouterProps } from 'react-router';
import { getSymbolFromCurrency } from 'eshop-defaults/lib/utilities';
import { getAvailability } from '../../utilities/microdata';
import { getConditionTextMapping, getImagePath } from '../../utilities/product';
import { ItemConditions } from '@bart.sk-ecommerce/react-microdata/lib/types';
import { setAdminContentPath } from '../../containers/App/actions';
import moment from 'moment';

interface Props {
  lang?: string;
  product: ThenArg<typeof API.loadProduct>;
  apiUrl: string;
  alternativeProducts: any;
  isFetchingAlternatives: boolean;
  crosssellProducts: ThenArg<typeof API.loadProductConnections>;
  bazarProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingCrosssell: boolean;
  dispatch: any;
  addToCart: any;
  langChanged: boolean;
  hostname: string;
  categoryEntities: Array<any>;
  isGift: boolean;
}

function Product({
  product,
  apiUrl,
  alternativeProducts,
  isFetchingAlternatives,
  crosssellProducts,
  bazarProducts,
  isFetchingCrosssell,
  dispatch,
  addToCart,
  lang,
  langChanged,
  location,
  hostname,
  categoryEntities,
  isGift,
}: Props & WithRouterProps) {
  const productIsPrelistingInitial =
    prop(product, 'status_b2b') === 0 || prop(product, 'status_b2c') === 0
      ? false
      : prop(product, 'prelisting');
  const [count, setCount] = React.useState(1);
  const [isLightBoxVisible, setIsLightBoxVisible] = useState(false);
  const [addToFavoriteOpen, setAddToFavoriteOpen] = useState(false);
  const [addToCartOpen, setAddToCartOpen] = useState(false);
  const [watchDogOpen, setWatchDogOpen] = useState(false);
  const [isOpenDescription, setIsOpenDescription] = useState(
    productIsPrelistingInitial ? false : true,
  );
  const [isOpenSpecification, setIsOpenSpecification] = useState(false);
  const [isOpenReviews, setIsOpenReviews] = useState(false);
  const [isOpenRelated, setIsOpenRelated] = useState(
    productIsPrelistingInitial ? true : false,
  );
  const info = getProductDetailInfo(product, apiUrl);
  const {
    name,
    publish,
    // description,
    // annotation,
    plu,
    ean,
    currency,
    availid,
    availability,
    price,
    brand,
    brandImage,
    brandId,
    brandUrl,
    sale,
    salePercentage,
    photogallery,
    isNew,
    image,
    url,
    condition,
    parentProductId,
    isFreeDelivery,
    isSaleB2b,
    isSaleB2c,
    isSale,
    oldPriceCorporate: oldPrice,
    isWaiting,
    hiddenSale,
    forbiddenOrderByDate,
    forbiddenOrderValid,
  } = info;

  const sales = prop(product, 'current_sales', []);
  const giftSale = sales.find(s => s.type === 'GIFTS' || 'GIFTS_FOR_PRICES');
  const firstPublish = publish[0];

  React.useEffect(() => {
    setCount(1);
  }, [product.product_id]);

  React.useEffect(() => {
    if (product && product.product_id) {
      dispatch(
        setAdminContentPath(
          `/admin/eshop/catalog/product-form/?id=${product.product_id}`,
        ),
      );
    }

    return () => {
      dispatch(setAdminContentPath(null));
    };
  }, [product]);

  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });

  let finalPhotogallery: string[] = [];
  if (image) {
    finalPhotogallery[0] = image;
    if (photogallery) {
      for (let i = 0; i < photogallery.length; i++) {
        finalPhotogallery.push(photogallery[i]);
      }
    }
  }

  let description = '';
  let specification = '';
  let short_description = '';
  let canonicalUrl = null;
  if (firstPublish) {
    description = firstPublish.content.json_content.annotation;
    if (description) {
      description = description.replace(/\.mp4/g, '.mp4#t=1');
      description = description.replace(/autoplay=""/g, 'autoplay="" muted=""');
    }
    specification = firstPublish.content.json_content.body;
    short_description = firstPublish.content.json_content.short_description;
    canonicalUrl = firstPublish.content.canonical_url;
  }

  let saleEnded = false;

  const productIsPrelisting =
    prop(product, 'status_b2b') === 0 || prop(product, 'status_b2c') === 0
      ? false
      : prop(product, 'prelisting');
  const isPrelisting = productIsPrelisting; //price > 0 ? false : productIsPrelisting;
  if (!productIsPrelisting && firstPublish && !firstPublish.publish) {
    saleEnded = true;
  }

  const brandLink = `${__r('routes:znacka', '/znacka')}/${brandUrl}`;
  let fullBrandImage: any = null;
  if (brandImage) {
    fullBrandImage = getImagePath('brands/' + brandImage, {
      width: 0,
      height: 10,
    });
  }

  React.useEffect(() => {
    const productIsPrelistingInitial =
      prop(product, 'status_b2b') === 0 || prop(product, 'status_b2c') === 0
        ? false
        : prop(product, 'prelisting');
    if (productIsPrelistingInitial) {
      setIsOpenDescription(false);
      setIsOpenRelated(true);
    } else if (saleEnded) {
      setIsOpenDescription(false);
      setIsOpenRelated(true);
    } else if (!description) {
      setIsOpenDescription(false);

      if (specification) {
        setIsOpenSpecification(true);
      } else if (
        prop(crosssellProducts, 'products.length', 0) > 0 ||
        prop(alternativeProducts, 'connections.total', 0) > 0
      ) {
        setIsOpenRelated(true);
      } else {
        setIsOpenReviews(true);
      }
    } else {
      setIsOpenDescription(true);
      setIsOpenRelated(false);
      setIsOpenReviews(false);
      setIsOpenSpecification(false);
    }
  }, [description, specification, product.product_id]);

  let downloads = [];
  if (product && product['publish']) {
    downloads = prop(product, 'publish.0.content.downloads');
  }

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const otherLang = getOtherLang(lang || '');
    if (langChanged) {
      const publish = product['publish'];
      const url = prop(publish, '0.product_url');
      window.location.href = `${currentUrl
        .replace(`.${lang}`, `.${getOtherLang(lang || '')}`)
        .replace(
          otherLang === 'cz' ? 'laica' : 'laicaitaly',
          otherLang === 'cz' ? 'laicaitaly' : 'laica',
        )
        .replace(
          otherLang === 'cz' ? 'kenwood-electronics' : 'kenwood',
          otherLang === 'cz' ? 'kenwood' : 'kenwood-electronics',
        )
        .replace(
          otherLang === 'cz' ? 'harmankardon' : 'harmanshop',
          otherLang === 'cz' ? 'harmanshop' : 'harmankardon',
        )}`;
    }
  }, [lang, langChanged]);

  const closeAll = () => {
    setIsOpenDescription(false);
    setIsOpenSpecification(false);
    setIsOpenReviews(false);
    setIsOpenRelated(false);
  };

  const changeDescriptionState = () => {
    closeAll();
    setIsOpenDescription(true);
  };

  const changeSpecificationState = () => {
    closeAll();
    setIsOpenSpecification(true);
  };

  const changeReviewsState = () => {
    closeAll();
    setIsOpenReviews(true);
  };

  const changeRelatedState = () => {
    closeAll();
    setIsOpenRelated(true);
  };

  const changeDimmerVisible = () => {
    document.body.style.overflow = isLightBoxVisible ? 'visible' : 'hidden';
    setIsLightBoxVisible(!isLightBoxVisible);
  };

  const openPhotogalleryModal = () => {
    document.body.style.overflow = 'hidden';
    setIsLightBoxVisible(true);
  };

  const changeAddToFavoriteOpen = () => {
    setAddToFavoriteOpen(!addToFavoriteOpen);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };

  const changeWatchDogOpen = () => {
    setWatchDogOpen(!watchDogOpen);
  };

  const closeModals = () => {
    document.body.style.overflow = 'visible';
    setAddToFavoriteOpen(false);
    setWatchDogOpen(false);
    setIsLightBoxVisible(false);
    setAddToCartOpen(false);
  };

  const handleAddToCart = (product: any, count: number) => {
    const { name, plu, price, currency, picture } = getProductDefaultInfo(
      product,
      undefined,
      undefined,
      true,
    );

    setAddedProduct({
      name: name,
      plu: plu,
      price: price,
      currency: currency,
      picture: picture,
    });
    changeAddToCartOpen();
    addToCart(product, count);
  };

  // React.useEffect(() => {
  //   dispatch(setBreadCrumbPath(BreadCrumbType.PRODUCT, product));
  // }, [product, dispatch]);
  // console.log(JSON.stringify(product));
  const renderUpperPart = () => {
    const salePercentageCalc = 100 - round((price / oldPrice) * 100, 2);
    return (
      <UpperWrapper>
        <LeftUpper>
          <SliderComponent
            type={'photogallery'}
            slides={finalPhotogallery}
            isSale={+(round(+oldPrice, 2) > round(+price, 2))}
            isNew={isNew}
            salePercentage={salePercentageCalc}
            changeDimmerVisible={changeDimmerVisible}
            openPhotogalleryModal={openPhotogalleryModal}
            isSecondHand={parentProductId ? true : false}
            isFreeDelivery={isFreeDelivery}
            saleEnded={saleEnded}
            isPrelisting={isPrelisting}
            hasGift={!!giftSale}
            hiddenSale={hiddenSale}
          />
        </LeftUpper>
        <RightUpper
          product={product}
          title={name}
          currency={currency}
          avail_id={availid}
          availability={availability}
          price={price}
          count={count}
          brand={brand}
          brandImage={fullBrandImage}
          brandLink={brandLink}
          plu={plu}
          ean={ean}
          handleCountChange={count => setCount(count)}
          oldPrice={oldPrice}
          sale={round(oldPrice, 2) > round(price, 2)}
          salePercentage={salePercentageCalc}
          description={short_description}
          addToFavoriteOpen={changeAddToFavoriteOpen}
          addToCart={handleAddToCart}
          watchDogOpen={changeWatchDogOpen}
          image={image}
          alternativeProducts={alternativeProducts.colors}
          isFetchingAlternatives={isFetchingAlternatives}
          crosssellProducts={crosssellProducts}
          apiUrl={apiUrl}
          url={url}
          hasWatchDog={false}
          mocPrice={price}
          vocPrice={price}
          vocPriceWithoutVat={price}
          showWithVat={true}
          showMoc={true}
          isFetchingBazar={false}
          bazarProducts={bazarProducts}
          canOrder={true}
          forbiddenOrder={forbiddenOrderByDate}
          saleEnded={saleEnded}
          isPrelisting={isPrelisting}
          giftSale={giftSale}
          isSecondHand={!!parentProductId}
          isNew={isNew}
          condition={condition}
          conditionTextMapping={getConditionTextMapping()}
        />
      </UpperWrapper>
    );
  };

  const renderBottomPart = () => {
    const descriptionRotation = isOpenDescription ? 0 : 180;
    const specificationRotation = isOpenSpecification ? 0 : 180;
    const reviewsRotation = isOpenReviews ? 0 : 180;
    const relatedRotation = isOpenRelated ? 0 : 180;

    let conditionText: string = '';
    switch (condition) {
      case '26': {
        conditionText = __('POSKODENY OBAL POPIS');
        break;
      }
      case '25': {
        conditionText = __('ROZBALENY POPIS');
        break;
      }
      case '17': {
        conditionText = __('ZANOVNE POPIS');
        break;
      }
      case '29': {
        conditionText = __('POUZIVANE');
        break;
      }
    }

    const forbiddenOrderUntilDate =
      forbiddenOrderByDate &&
      forbiddenOrderValid &&
      moment(forbiddenOrderValid).isValid()
        ? moment(forbiddenOrderValid).format('DD.MM.YYYY')
        : undefined;

    const alternativeCategories: Array<any> = [];
    if (
      (product as any).productCategories &&
      (product as any).productCategories.length
    ) {
      for (const productCategory of (product as any).productCategories) {
        let isFavorite = false;
        if (
          productCategory.parent_categories &&
          productCategory.parent_categories.length &&
          productCategory.parent_categories.find(
            pc => pc.category_number && pc.category_number.includes('FAVORITE'),
          )
        ) {
          isFavorite = true;
        }
        const categoryEntity =
          categoryEntities[`${productCategory.category_id}`];
        if (categoryEntity && !isFavorite) {
          alternativeCategories.push(categoryEntity);
        }
      }
    }

    const calculatedImages = prop(
      firstPublish,
      'content.calculated_meta.images',
      [],
    );
    const calculatedVideos = prop(
      firstPublish,
      'content.calculated_meta.videos',
      [],
    );
    const calculatedYoutubeVideos = prop(
      firstPublish,
      'content.calculated_meta.youtubeVideos',
      [],
    );

    return (
      <>
        {price && price > 0 ? (
          <ProductMicrodata
            name={name}
            brand={brand}
            sku={plu}
            description={short_description}
            gtin={ean}
            weight={
              product && (product as any).product_weight
                ? (product as any).product_weight + 'kg'
                : undefined
            }
            width={
              product && product.main_good && (product.main_good as any).width
                ? (product.main_good as any).width + 'cm'
                : undefined
            }
            height={
              product && product.main_good && (product.main_good as any).height
                ? (product.main_good as any).height + 'cm'
                : undefined
            }
            depth={
              product && product.main_good && (product.main_good as any).length
                ? (product.main_good as any).length + 'cm'
                : undefined
            }
            mpn={plu}
            offers={
              price && price > 0
                ? {
                    availability:
                      getAvailability(
                        availability,
                        prop(product, 'main_good.on_stock_count'),
                        isNew,
                        saleEnded,
                        isWaiting,
                      ) || 'OutOfStock',
                    price: price,
                    priceCurrency: currency,
                    itemCondition: condition
                      ? ItemConditions.UsedCondition
                      : ItemConditions.NewCondition,
                  }
                : undefined
            }
            videoObject={calculatedVideos
              .map(item => {
                return { contentUrl: item.url };
              })
              .concat(
                calculatedYoutubeVideos.map(item => {
                  return {
                    contentUrl: item.url,
                    thumbnailUrl: item.thumbnailUrl,
                    description: item.description
                      ? item.description
                      : item.name,
                    uploadDate: item.uploadDate,
                    name: item.name,
                  };
                }),
              )}
            imageObject={calculatedImages
              .map(item => {
                return {
                  url: item.url,
                  width: item.width,
                  height: item.height,
                };
              })
              .concat(
                info.image
                  ? [
                      {
                        url: getImagePath('product/' + info.image, {
                          width: 160,
                          height: 160,
                        }),
                      },
                    ]
                  : [],
              )}
          />
        ) : (
          <></>
        )}
        <BottomWrapper>
          <BottomMenuWrapper className="product_tabs">
            {description ? (
              <BottomMenuItem
                isOpen={isOpenDescription}
                onClick={changeDescriptionState}
              >
                <MenuItemContent>
                  <SvgIcon icon={IconType.description} />
                  <BottomMenuText>{__('Popis produktu')}</BottomMenuText>
                </MenuItemContent>
              </BottomMenuItem>
            ) : null}
            {specification ? (
              <BottomMenuItem
                isOpen={isOpenSpecification}
                onClick={changeSpecificationState}
              >
                <MenuItemContent>
                  <SvgIcon icon={IconType.specification} />
                  <BottomMenuText>{__('Špecifikácia')}</BottomMenuText>
                </MenuItemContent>
              </BottomMenuItem>
            ) : null}
            {prop(product, 'web_content_connections', []) &&
              prop(product, 'web_content_connections', []).length > 0 && (
                <BottomMenuItem
                  isOpen={isOpenReviews}
                  onClick={changeReviewsState}
                >
                  <MenuItemContent>
                    <SvgIcon icon={IconType.reviews} />
                    <BottomMenuText>{__('Články a recenzie')}</BottomMenuText>
                  </MenuItemContent>
                </BottomMenuItem>
              )}
            {crosssellProducts &&
              crosssellProducts.products &&
              (crosssellProducts.products.length > 0 ||
                prop(alternativeProducts, 'connections.total', 0)) > 0 && (
                <BottomMenuItem
                  isOpen={isOpenRelated}
                  onClick={changeRelatedState}
                >
                  <MenuItemContent>
                    <SvgIcon icon={IconType.related} />
                    <BottomMenuText>{__('Súvisiace produkty')}</BottomMenuText>
                  </MenuItemContent>
                </BottomMenuItem>
              )}
          </BottomMenuWrapper>

          <BottomContentWrapper>
            <Description
              description={description}
              isOpen={isOpenDescription}
              condition={conditionText}
              forbiddenOrderUntilDate={forbiddenOrderUntilDate}
            />

            <Specification
              specification={specification}
              isOpen={isOpenSpecification}
              documents={downloads}
              condition={description ? '' : conditionText}
            />

            <Reviews
              isOpen={isOpenReviews}
              articles={prop(product, 'web_content_connections', [])}
            />
            {prop(alternativeProducts, 'connections.total', 0) > 0 && (
              <>
                <Related
                  title={__('Dostupné alternatívy k ') + name}
                  isOpen={isOpenRelated}
                  products={alternativeProducts.connections}
                  apiUrl={apiUrl}
                  conditionTextMapping={getConditionTextMapping()}
                />
                {isOpenRelated &&
                  prop(crosssellProducts, 'products.length', 0) > 0 && (
                    <Line>
                      <VerticalLine />
                      <HorizontalLine />
                      <VerticalLine />
                    </Line>
                  )}
              </>
            )}
            {isFetchingCrosssell ? (
              <ZemplinLoaderWrapper height={300} />
            ) : (
              <Related
                title={__('Súvisiace produkty')}
                isOpen={isOpenRelated}
                products={crosssellProducts}
                apiUrl={apiUrl}
                conditionTextMapping={getConditionTextMapping()}
              />
            )}

            {(saleEnded || isPrelisting) &&
            alternativeCategories &&
            alternativeCategories.length ? (
              <RelatedCategories
                title={
                  __('Alternatívy Vám nevyhovujú? Skúste priamo kategórie') +
                  ':'
                }
                isOpen={isOpenRelated}
                categories={alternativeCategories}
                apiUrl={apiUrl}
                lang={lang}
              />
            ) : (
              <></>
            )}
          </BottomContentWrapper>
        </BottomWrapper>
        <MobileBottomWrapper>
          {description ? (
            <MobileBottomItem
              onClick={() => setIsOpenDescription(!isOpenDescription)}
              isOpen={isOpenDescription}
              className="alternative_tab_mobile"
            >
              <FlexRowCenterVertical>
                <MenuItemContent>
                  <SvgIcon icon={IconType.description} />
                  <BottomMenuText>{__('Popis produktu')}</BottomMenuText>
                </MenuItemContent>
                <IconImg
                  rotate={descriptionRotation}
                  src={'/images/dsi/arrow-down.svg'}
                  alt="arrow-icon"
                  width={24}
                  height={24}
                />
              </FlexRowCenterVertical>
              <Description
                description={description}
                isOpen={isOpenDescription}
                condition={conditionText}
                forbiddenOrderUntilDate={forbiddenOrderUntilDate}
              />
            </MobileBottomItem>
          ) : null}
          {specification ? (
            <MobileBottomItem
              onClick={() => setIsOpenSpecification(!isOpenSpecification)}
              isOpen={isOpenSpecification}
            >
              <FlexRowCenterVertical>
                <MenuItemContent>
                  <SvgIcon icon={IconType.specification} />
                  <BottomMenuText>{__('Špecifikácia')}</BottomMenuText>
                </MenuItemContent>
                <IconImg
                  rotate={specificationRotation}
                  src={'/images/dsi/arrow-down.svg'}
                  alt="arrow-icon"
                  width={24}
                  height={24}
                />
              </FlexRowCenterVertical>
              <Specification
                isOpen={isOpenSpecification}
                downloads={downloads}
                specification={specification}
                condition={description ? '' : conditionText}
              />
            </MobileBottomItem>
          ) : null}
          {prop(product, 'web_content_connections', []) &&
            prop(product, 'web_content_connections', []).length > 0 && (
              <MobileBottomItem
                onClick={() => setIsOpenReviews(!isOpenReviews)}
                isOpen={isOpenReviews}
              >
                <FlexRowCenterVertical>
                  <MenuItemContent>
                    <SvgIcon icon={IconType.reviews} />
                    <BottomMenuText>{__('Články a recenzie')}</BottomMenuText>
                  </MenuItemContent>

                  <IconImg
                    rotate={reviewsRotation}
                    src={'/images/dsi/arrow-down.svg'}
                    alt="arrow-icon"
                    width={24}
                    height={24}
                  />
                </FlexRowCenterVertical>
                <Reviews
                  isOpen={isOpenReviews}
                  articles={prop(product, 'web_content_connections', [])}
                />
              </MobileBottomItem>
            )}
          {crosssellProducts &&
            crosssellProducts.products &&
            (crosssellProducts.products.length > 0 ||
              prop(alternativeProducts, 'connections.total', 0)) > 0 && (
              <MobileBottomItem
                onClick={() => setIsOpenRelated(!isOpenRelated)}
                isOpen={isOpenRelated}
              >
                <FlexRowCenterVertical>
                  <MenuItemContent>
                    <SvgIcon icon={IconType.related} />
                    <BottomMenuText>
                      {__('Súvisiace produkty a alternatívy')}
                    </BottomMenuText>
                  </MenuItemContent>
                  <IconImg
                    rotate={relatedRotation}
                    src={'/images/dsi/arrow-down.svg'}
                    alt="arrow-icon"
                    width={24}
                    height={24}
                  />
                </FlexRowCenterVertical>
                {prop(alternativeProducts, 'connections.total', 0) > 0 && (
                  <Related
                    title={__('Alternatívy')}
                    isOpen={isOpenRelated}
                    products={alternativeProducts.connections}
                    apiUrl={apiUrl}
                    conditionTextMapping={getConditionTextMapping()}
                  />
                )}
                {isFetchingCrosssell ? (
                  <ZemplinLoaderWrapper height={300} />
                ) : (
                  <Related
                    title={__('Súvisiace produkty')}
                    isOpen={isOpenRelated}
                    products={crosssellProducts}
                    apiUrl={apiUrl}
                    conditionTextMapping={getConditionTextMapping()}
                  />
                )}

                {saleEnded &&
                alternativeCategories &&
                alternativeCategories.length ? (
                  <RelatedCategories
                    title={__('Alternatívne kategórie') + ':'}
                    isOpen={isOpenRelated}
                    categories={alternativeCategories}
                    apiUrl={apiUrl}
                  />
                ) : (
                  <></>
                )}
              </MobileBottomItem>
            )}
        </MobileBottomWrapper>
      </>
    );
  };

  return (
    <>
      {isLightBoxVisible && (
        <>
          <Dimmer height={100} zIndex={100} topZero={true} />
          <StyledOutsideClick handleFunction={closeModals}>
            <LightBox>
              <SliderComponent
                type={'photogallery'}
                slides={finalPhotogallery}
                isSale={isSale}
                isNew={isNew}
                changeDimmerVisible={changeDimmerVisible}
                isFullscreen={true}
              />
            </LightBox>
          </StyledOutsideClick>
        </>
      )}
      {/* {addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={addedProduct.name}
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )} */}
      {addToFavoriteOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'favorite'}
                addToFavoriteOpen={changeAddToFavoriteOpen}
                productName={name}
                picture={image}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )}
      {watchDogOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <WatchDog
                watchDogOpen={changeWatchDogOpen}
                productName={name}
                picture={image}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )}
      <Wrapper className="container container--wide">
        <MetaTags
          tags={getProductMetaTags(product, lang, hostname)}
          canonicalUrl={canonicalUrl}
          noindex={isGift || !!parentProductId}
          nofollow={isGift}
          hrefLangs={(product as any).lang_versions}
          hostname={hostname}
        />
        {renderUpperPart()}
        {renderBottomPart()}
      </Wrapper>
    </>
  );
}

const Line = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0 ${rem(56)};
`;
const HorizontalLine = styled.div`
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  border-bottom: 0.0625rem solid #cccccc;
  margin: 2px;
  width: 100%;
`;
const VerticalLine = styled.div`
  border-left: 0.0625rem solid #cccccc;
  width: 0px;
  height: ${rem(24)};
`;

const StyledOutsideClick = styled(OutsideClick)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  z-index: 0;
`;

const Wrapper = styled(FlexCol)`
  padding-bottom: ${rem(80)};
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(16)} ${rem(16)} ${rem(32)};
  `}
`;

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
`;

const LightBox = styled(StyledModal)`
  width: 90%;
  height: 90%;
  margin: 5vh 5%;

  ${({ theme }) => theme.mediab.l925`
    max-height: ${rem(550)};
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const AddModal = styled(StyledModal)`
  width: ${rem(460)};

  ${({ theme }) => theme.mediab.s450`
    width: 90%;
  `}
`;

const LeftUpper = styled(FlexCol)`
  width: 100%;
  color: #aaaaaa;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-within {
    color: ${props => props.theme.colors.btnPrimary};
  }

  ${({ theme }) => theme.mediab.s450`
    max-width: initial;
  `}
`;

const BottomWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const MobileBottomItem = styled(FlexColCenterVertical)<{ isOpen: boolean }>`
  width: 100%;
  padding: ${rem(16)};
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.white : theme.colors.categoryFilterBackground};
  justify-content: space-between;
`;

const MobileBottomWrapper = styled(BottomWrapper)`
  display: none;
  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
`;

const UpperWrapper = styled(FlexRow)`
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    margin-bottom: ${rem(16)};
  `}
`;

const BottomMenuWrapper = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.categoryFilterBackground};
`;

const BottomContentWrapper = styled.div``;

const BottomMenuItem = styled(FlexRowCenterVertical)<{ isOpen: boolean }>`
  width: 100%;
  padding: ${rem(16)} 0;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.white : 'initial'};
  border-radius: 4px 4px 0px 0px;
  box-shadow: ${props =>
    props.isOpen ? `0 ${rem(1)} ${rem(4)} ${rgba('#000000', 0.08)}` : 'none'};
  clip-path: inset(0 -5px 0 -2px);

  cursor: pointer;
  &:hover {
    text-decoration: ${({ isOpen }) => (isOpen ? 'none' : 'underline')};
  }
`;

const BottomMenuText = styled(TextP)`
  font-size: ${rem(18)};
  font-weight: 500;
  margin-left: ${rem(8)};
`;

const MenuItemContent = styled(FlexRowCenterVertical)`
  margin: 0 auto;
  ${({ theme }) => theme.mediab.l925`
    margin-left: 0;
  `}
`;

export default withRouter(Product);

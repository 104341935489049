import API, { ThenArg } from '../../services/API';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import {
  FilterHeaderItemName,
  FilterHeaderItemWrapper,
  InfoIcon,
  InfoIconLink,
  LeftSideWrapper,
  // LeftSideWrapper,
  MobileFilterHeaderItem,
  MobileFilterWrapper,
  MobileHeaderFilterWrapper,
  RightSideWrapper,
  SortSelect,
  SortSelectInfoWrapper,
  SortWrapper,
  StyledOutsideClick,
  SubCatNameWrapper,
  SubCategory,
  SubImage,
  SubName,
  defaultSortOption,
  sortOptions,
} from '../Category/Category';
import {
  DsiProductFilter as ProductFilter,
  DsiViewSwitcher as ViewSwitcher,
  DsiProductList as ProductList,
  DsiProductTableList as ProductTableList,
  DsiPagination as Pagination,
  DsiSearchArticlesList as SearchArticlesList,
  CorporateCategoryHeader as CategoryHeader,
} from 'eshop-defaults';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddModal } from '../Product/Product';
import { AddTo } from '../Product/AddTo';
import { arrayToObject, prop, setProductViewCookie } from '../../utilities';
import { __, __r } from 'react-i18n/lib';
import { FlexRow, ZemplinLoaderWrapper } from 'eshop-defaults/lib';
import { useLocalStorage } from '../../utilities/hooks';
import { addProductToFavorites } from '../../containers/App/actions';
import { rem } from 'polished';
import {
  getProductDefaultInfo,
  resolveCategoryUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import {
  attribsObjectToUrl,
  urlAttribsToObject,
} from '../../containers/Category/categorySlice';
import * as cookie from 'react-cookies';
import { JBL_PRODUCT_VIEW_COOKIE } from '../../containers/App/constants';
import { push } from 'react-router-redux';
import { getConditionTextMapping, getImagePath } from '../../utilities/product';
import { ResetFilter } from 'eshop-defaults/lib/components/Dsi/Category/ResetFilter';
import {
  getAttribNameFromAttribObj,
  getAttribParameterFromAttribObj,
} from '../../utilities/category';
import { CategoryFilter } from 'eshop-defaults/lib/components/Dsi/Category/CategoryFilter';

enum FilterType {
  RANGE = 'RANGE',
  OPTIONS = 'CHECKBOX',
  LINKS = 'LINKS',
  CHECK = 'CHECK',
}

interface Props {
  user: any;
  apiUrl: string;
  query: string;
  dispatch: any;
  isFetching: boolean;
  products: ThenArg<typeof API.searchProducts>;
  addToCart: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  currency: string;
  categoryAttribs: any;
  router: any;
  searchTerm: string;
  location: any;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  domainId: number;
  title?: string;
  description?: string;
  titleImage?: string | null;
  titleLink?: string | null;
  categories?: any;
  subcategoryFilterAttribs?: string | null;
  lang: string;
  byId?: any;
  ignoreFilters?: string[];
}

function SearchResults({
  user,
  apiUrl,
  query,
  dispatch,
  isFetching,
  products,
  addToCart,
  router,
  searchTerm,
  location,
  articles,
  isFetchingArticles,
  domainId,
  title,
  description,
  titleImage,
  titleLink,
  categories,
  subcategoryFilterAttribs,
  filterData,
  currency,
  categoryAttribs,
  lang,
  byId,
  ignoreFilters,
}: Props) {
  const [currentView, setCurrentView] = useLocalStorage(
    'product-view',
    cookie.load(JBL_PRODUCT_VIEW_COOKIE) || 'card',
  );
  const cookieProductView = cookie.load(JBL_PRODUCT_VIEW_COOKIE);

  React.useEffect(() => {
    if (cookieProductView) {
      setCurrentView(cookieProductView);
    }
  }, [cookieProductView]);

  const isCardView = currentView !== 'list';

  const [addToFavoriteOpen, setAddToFavoriteOpen] = useState(false);
  const [addToCartOpen, setAddToCartOpen] = useState(false);

  const [isActiveCategory, setIsActiveCategory] = useState(true);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });

  const [isShowingAllAttribs, setIsShowingAllAttribs] = React.useState(false);

  const catUrl = prop(location, 'pathname');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);

  const addToRequest = () => {
    //console.log('add to request');
  };

  const addProductToFavorite = id => {
    dispatch(addProductToFavorites(user.id, id));
    setAddToFavoriteOpen(true);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };

  const changeView = () => {
    const newView = currentView === 'card' ? 'list' : 'card';
    setProductViewCookie(newView);
    setCurrentView(newView);
  };

  const closeModals = () => {
    setAddToFavoriteOpen(false);
  };

  const changeAddToFavoriteOpen = () => {
    setAddToFavoriteOpen(!addToFavoriteOpen);
  };

  const handleAddToCart = (product: any, count: number) => {
    const { name, plu, price, currency, picture } = getProductDefaultInfo(
      product,
      undefined,
      undefined,
      true,
    );

    setAddedProduct({
      name: name,
      plu: plu,
      price: price,
      currency: currency,
      picture: picture,
    });
    setAddToCartOpen(true);

    if (count === 0) {
      count = 1;
    }

    addToCart(product, count);
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        }
      | any,
  ) => {
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(categoryAttribs, `${attribId}.min`);
          const attribDataMax = prop(categoryAttribs, `${attribId}.max`);

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');
        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;

        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else if (!valueId) {
          delete currentRangeAttribFilters[attribId];
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }
        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
            rangeAttribs: currentRangeAttribFilters,
          }),
        );

        break;
      }

      case FilterType.CHECK: {
        handleCheckboxChange({
          name: newData.target.name,
          value: newData.target.value,
        });
        break;
      }

      default:
        break;
    }
  };

  const parameters = prop(urlAttribsObj, 'activeAttribs');
  const activeAttribs = JSON.parse(
    JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
  );
  const activeRangeAttribs = JSON.parse(
    JSON.stringify(prop(urlAttribsObj, 'rangeAttribs', {})),
  );
  const activeCombinedAttribs = { ...activeAttribs, ...activeRangeAttribs };

  if (activeCombinedAttribs && categoryAttribs) {
    Object.keys(activeCombinedAttribs).map(key => {
      const activeValues = activeCombinedAttribs[key].values;
      if (!activeValues) {
        activeCombinedAttribs[key].attribName = getAttribNameFromAttribObj(
          prop(categoryAttribs, `${key}.attrib_data`),
          lang,
        );
        activeCombinedAttribs[key].unit = prop(
          categoryAttribs,
          `${key}.attrib_data.attrib_unit`,
        );
      } else {
        const valuesObj = arrayToObject(
          categoryAttribs[key].values,
          'attrib_value',
        );
        const newValues: any[] = [];
        activeValues.map(value => {
          newValues.push({
            valueId: value,
            name:
              getAttribParameterFromAttribObj(
                prop(valuesObj, `${value}.info`),
                'attrib_value',
                lang,
              ) || prop(valuesObj, `${value}.info.attrib_value`, value),
            attribName: getAttribNameFromAttribObj(
              prop(categoryAttribs, `${key}.attrib_data`),
              lang,
            ),
          });
        });
        activeAttribs[key].values = newValues;
      }
    });
  }

  const attribValuesActive = parameters ? parameters.split(',') : [];
  const activeValues = attribValuesActive
    ? attribValuesActive.map(act => {
        const [, val] = act.split('|');
        return val;
      })
    : [];

  const minP = Math.floor(+prop(products, 'price_min', 0));
  const maxP = Math.ceil(prop(products, 'price_max', 0));
  const currentMin = prop(location, 'query.min', minP);
  const currentMax = prop(location, 'query.max', maxP);

  const toggleShowingAllParameters = () => {
    setIsShowingAllAttribs(isShowingAllAttribs => !isShowingAllAttribs);
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  const renderMobileFilter = () => {
    const resetStateFilter = () => {
      if (isOpenFilter) {
        setIsOpenFilter(!isOpenFilter);
        setIsActiveCategory(!isActiveCategory);
      } else {
        if (isOpenCategory) {
          setIsOpenCategory(!isOpenCategory);
          setIsActiveCategory(!isActiveCategory);
          setIsActiveFilter(!isActiveFilter);
          setIsOpenFilter(!isOpenFilter);
        } else {
          setIsOpenFilter(!isOpenFilter);
          setIsActiveCategory(!isActiveCategory);
        }
      }
    };

    return (
      <>
        <MobileHeaderFilterWrapper>
          <MobileFilterHeaderItem
            isOpen={isOpenFilter}
            isActive={isActiveFilter}
            onClick={resetStateFilter}
          >
            <FilterHeaderItemWrapper>
              <FilterHeaderItemName>Filter</FilterHeaderItemName>
            </FilterHeaderItemWrapper>
          </MobileFilterHeaderItem>
        </MobileHeaderFilterWrapper>
        {/* <MobileFilterWrapper isOpen={isOpenFilter}>
          <ProductFilter isMobile={true} />
        </MobileFilterWrapper> */}
      </>
    );
  };

  const renderAddToFavoritesModal = () => {
    return (
      addToFavoriteOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'favorite'}
                addToFavoriteOpen={changeAddToFavoriteOpen}
                productName={'product_name'}
                picture={''}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  const renderCustomFilters = (isMobile = false) => {
    if (!categoryAttribs) {
      return null;
    }

    let keys = Object.keys(categoryAttribs);
    const activeKeys = keys.filter(k =>
      prop(categoryAttribs, `${k}.hasActive`),
    );
    const otherKeys = keys.filter(
      k => !prop(categoryAttribs, `${k}.hasActive`),
    );
    otherKeys.sort(function(a, b) {
      const aSort = prop(categoryAttribs[a], 'attrib_data.attrib_sort');
      const bSort = prop(categoryAttribs[b], 'attrib_data.attrib_sort');

      //   const isRange = prop(attribData, 'attrib_type') === 'rozsah';
      // const hasActive = isRange
      //     ? activeRangeAttribs[prop(attribData, 'attrib_id')]
      //     : values.find(v => activeValues.includes(v.attrib_value));
      return (
        prop(categoryAttribs[a], 'attrib_data.attrib_sort') -
        prop(categoryAttribs[b], 'attrib_data.attrib_sort')
      );
    });

    keys = [...activeKeys, ...otherKeys];
    keys = keys.filter(key => {
      const attrib = categoryAttribs[key];
      const attribData = prop(attrib, 'attrib_data');
      const isRange = prop(attribData, 'attrib_type') === 'rozsah';
      const values = attrib.values || [];
      const valuesWithDocCount = values.filter(v => v.doc_count > 0);
      const hasActive = isRange
        ? activeRangeAttribs[prop(attribData, 'attrib_id')]
        : values.find(v => activeValues.includes(v.attrib_value));
      const hasMoreThanOneValue = valuesWithDocCount.length > 1;
      if (!isRange && !hasMoreThanOneValue && !hasActive) {
        return null;
      }

      return key;
    });

    const keysAltered = isShowingAllAttribs
      ? keys
      : keys.slice(0, 5 + activeKeys.length);

    return (
      <>
        {categoryAttribs &&
          keysAltered.map(key => {
            const attrib = categoryAttribs[key];
            const attribData = prop(attrib, 'attrib_data');
            const isRange = prop(attribData, 'attrib_type') === 'rozsah';
            const values = attrib.values || [];
            const hasActive = isRange
              ? activeRangeAttribs[prop(attribData, 'attrib_id')]
              : values.find(v => activeValues.includes(v.attrib_value));

            return (
              <CategoryFilter
                type={isRange ? FilterType.RANGE : FilterType.OPTIONS}
                name={getAttribNameFromAttribObj(
                  prop(categoryAttribs, `${key}.attrib_data`),
                  lang,
                )}
                initialIsOpen={hasActive ? true : false}
                filterData={
                  isRange
                    ? {
                        min: attrib.min,
                        max: attrib.max,
                        activeMax:
                          prop(
                            activeRangeAttribs[prop(attribData, 'attrib_id')],
                            'max',
                          ) || attrib.max,
                        activeMin:
                          prop(
                            activeRangeAttribs[prop(attribData, 'attrib_id')],
                            'min',
                          ) || attrib.min,
                        attribId: prop(attribData, 'attrib_id'),
                      }
                    : {
                        attribId: prop(attribData, 'attrib_id'),
                        values: values.map(v => ({
                          ...v,
                          info: {
                            ...v.info,
                            attrib_value: getAttribParameterFromAttribObj(
                              v.info,
                              'attrib_value',
                              lang,
                            ),
                          },
                        })),
                      }
                }
                handleFilterChange={handleFilterChange}
                isMobile={isMobile}
                unit={prop(attribData, 'attrib_unit')}
                activeValues={activeValues}
              />
            );
          })}
        {keysAltered && keys.length > keysAltered.length ? (
          <ShowAllAttribs onClick={toggleShowingAllParameters}>
            {isShowingAllAttribs
              ? __('Skryť ďalšie parametre')
              : __('Zobraziť ďalšie parametre')}
          </ShowAllAttribs>
        ) : null}
      </>
    );
  };

  const renderLeftSide = () => {
    if (isFetching) {
      return null;
    }

    return (
      <LeftSideWrapper>
        <ProductFilter
          handleFilterChange={handleFilterChange}
          isMobile={false}
          priceInitial={{
            min: minP,
            max: maxP,
          }}
          priceCurrent={{ min: +currentMin, max: +currentMax }}
          currency={currency}
          data={urlAttribsObj}
          hasOnlyWithParentFitler={true}
          aggData={products}
          hasOnStockFilter={true}
          ignoreFilters={ignoreFilters}
        />
        {renderCustomFilters()}
        {/*<ResetFilter url={catUrl} name={__('Vymazať filter')} /> */}
      </LeftSideWrapper>
    );
  };

  const renderRightSide = () => {
    const offset = prop(products, 'offset', 0);
    const limit = prop(products, 'limit', 0);
    const total = prop(products, 'total', 0);

    const onSelectChange = e => {
      if (window) {
        const value = e.target.value;

        const [sort, sortDir] = value.split('-');
        router.push(
          attribsObjectToUrl(window.location.pathname, {
            ...urlAttribsObj,
            sort,
            sortDir: sortDir ? sortDir : null,
            q: searchTerm,
          }),
        );
      }
    };

    const currentSortValue = prop(location, 'query.sort', '');
    const currentSortDirValue = prop(location, 'query.sortDir', '');

    const subCategories = categories;

    const localSortOptions: Array<{ name: string; value: string }> = [
      {
        value: 'available_desc_price_desc',
        name: __('Základné radenie'),
      },
      {
        value: 'alphabetically-asc',
        name: __('Názov A-Z'),
      },
      {
        value: 'alphabetically-desc',
        name: __('Názov Z-A'),
      },
      {
        value: 'price-asc',
        name: __('Od najlacnejšieho'),
      },
      {
        value: 'price-desc',
        name: __('Od najdrahšieho'),
      },
    ];

    return (
      <RightSideWrapper>
        <CategoryHeader
          title={title ? title : ''}
          desc={description ? description : ''}
          short_desc={''}
          title_img_src={
            titleImage
              ? getImagePath(titleImage, { width: 0, height: 15 })
              : undefined
          }
          title_link={titleLink ? titleLink : undefined}
        />

        {subCategories ? (
          <SubCategoriesWrapper>
            {subCategories &&
              Array.isArray(subCategories) &&
              subCategories.map((item: any) => (
                <SubCategory
                  to={
                    item.weburl
                      ? resolveCategoryUrl(
                          item.redirect_category_id,
                          `${prop(
                            byId,
                            `${item.redirect_category_id}.publish.0.url`,
                          ) || item.weburl}`,
                          'CATEGORYTEXT/URL',
                        )
                      : resolveCategoryUrl(
                          item.category_id,
                          `${prop(
                            byId,
                            `${item.redirect_category_id}.publish.0.url`,
                          ) ||
                            prop(item, 'publish.0.url') ||
                            item.url ||
                            'url'}`,
                          'CATEGORYTEXT/URL',
                        )
                  }
                  draggable={false}
                >
                  <SubImage
                    src={
                      item.image
                        ? getImagePath(
                            item.image,
                            { width: 40, height: 0 },
                            false,
                          )
                        : '/images/icons/placeholder.svg'
                    }
                    alt={`${__('Obrázok kategórie')} ${prop(
                      item,
                      'publish.0.content.json_content.shortname',
                    ) ||
                      prop(item, 'publish.0.category_name') ||
                      item.category_name}`}
                  />
                  <SubCatNameWrapper>
                    <SubName>
                      {prop(item, 'publish.0.content.json_content.shortname') ||
                        prop(item, 'publish.0.category_name') ||
                        item.category_name}
                    </SubName>
                  </SubCatNameWrapper>
                </SubCategory>
              ))}
          </SubCategoriesWrapper>
        ) : (
          <></>
        )}

        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !products /* || !products.products*/ ? null : (
          <>
            {renderNumberOfProducts()}
            <SortWrapper>
              <SortSelectInfoWrapper>
                <SortSelect
                  name="sort"
                  id="sort"
                  options={localSortOptions}
                  // placeholder={__('Najpredávanejšie')}
                  onChange={onSelectChange}
                  value={
                    currentSortValue
                      ? `${currentSortValue}-${currentSortDirValue}`
                      : defaultSortOption
                  }
                />
                <InfoIconLink
                  href={__r('/informacie-o-radeni', '/informacie-o-radeni')}
                >
                  <InfoIcon src="/images/icons/outline-info.svg" />
                </InfoIconLink>
              </SortSelectInfoWrapper>
              <ViewSwitcher changeView={changeView} currentView={currentView} />
            </SortWrapper>
            {renderProductsCardView()}
            {renderProductsListView()}
            <Pagination
              query={`${location.pathname}${
                location.search ? location.search : '?'
              }`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
            {renderArticles()}
          </>
        )}
      </RightSideWrapper>
    );
  };

  const renderArticles = () => {
    const moreArticlesUrl = `${__r(
      'routes:clanky',
      '/clanky',
    )}?q=${searchTerm}`;
    return (
      <SearchArticlesList
        articles={prop(articles, 'articles', [])}
        isFetching={isFetchingArticles}
        moreArticlesUrl={moreArticlesUrl}
        numOfArticles={prop(articles, 'total', 0)}
      />
    );
  };

  const renderNumberOfProducts = () => {
    const total = prop(products, 'total');
    let foundText = __('Našlo sa');
    let resultsText = __('produktov');
    if (products) {
      foundText =
        total === 1
          ? __('Našiel sa')
          : total < 5 && total > 0
          ? __('Našli sa')
          : __('Našlo sa');
      resultsText =
        total === 1
          ? __('produkt')
          : total < 5 && total > 0
          ? __('produkty')
          : __('produktov');
    }

    return (
      <ResultsTotal>
        {`${__(foundText)}`} <b>{products && total}</b>
        {` ${__(resultsText)}`}
      </ResultsTotal>
    );
  };

  const renderProductsCardView = () => {
    return (
      <ProductList
        apiUrl={apiUrl}
        isFetching={isFetching}
        products={prop(products, 'products', []) || []}
        addToCart={handleAddToCart}
        addToRequest={addToRequest}
        visible={isCardView}
        addToFavorite={addProductToFavorite}
        hasFavourite={false}
        showWithVat={true}
        showMoc={true}
        canOrder={true}
        isCorporate={true}
        domainId={domainId}
        conditionTextMapping={getConditionTextMapping()}
      />
    );
  };

  const renderProductsListView = () => {
    return (
      <ProductTableList
        apiUrl={apiUrl}
        isFetching={isFetching}
        products={prop(products, 'products', []) || []}
        addToCart={handleAddToCart}
        addToRequest={addToRequest}
        visible={!isCardView}
        showSumOfVariants={true}
        hasFavourite={false}
        hasWatchdog={false}
        showWithVat={true}
        showMoc={true}
        canOrder={true}
        isCorporate={true}
        domainId={domainId}
        conditionTextMapping={getConditionTextMapping()}
      />
    );
  };

  const renderAddToCartModal = () => {
    return (
      addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={addedProduct.name}
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  return (
    <>
      {/* {renderAddToCartModal()} */}
      {renderAddToFavoritesModal()}
      <Wrapper className="container container--wide">
        {renderLeftSide()}
        {renderRightSide()}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(80)} !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(32)} !important;
  `}
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

const ShowAllAttribs = styled.div`
  background: ${({ theme, isMobile }) =>
    isMobile ? theme.colors.white : theme.categoryFilter.bgColor};
  padding: ${({ theme }) => theme.categoryFilter.padding};
  margin: ${({ theme }) => theme.categoryFilter.margin};
  border: ${({ theme }) => theme.categoryFilter.border};
  border-radius: ${({ theme }) => theme.categoryFilter.borderRadius};
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: ${rem(16)} ${rem(32)};
    /* font-weight: 500;
    line-height: ${rem(32)};
    font-size: ${rem(20)}; */
  cursor: pointer;
`;

const SubCategoriesWrapper = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${rem(16)};
  grid-auto-rows: minmax(64, auto);
  margin-bottom: ${rem(16)};
  margin-top: ${rem(12)}
    ${({ theme }) => theme.mediab.l1150`
    grid-template-columns: repeat(3, 1fr);
  `};

  @media (max-width: ${rem(991)}) {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.mediab.m810`
      display: none;
  `}
`;

export { SearchResults };

import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  searchResultsSelector,
  searchResultsIsFetchingSelector,
  searchResultsArticlesSelector,
  searchResultsIsFetchingArticlesSelector,
  hostnameSelector,
  brandDetailSelector,
  brandCategoriesSelector,
  currencySelector,
} from '../App/selectors';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  loadBrandCategories,
  loadBrandDetail,
  loadSearchTermArticlesResults,
  loadSearchTermResults,
} from '../App/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';
import { SearchResults } from '../../components/Search/SearchResults';
import { addItemToCart } from '../Cart/cartSlice';
import {
  resolveBaseUrlByLang,
  resolveDomainByHostname,
} from '../../configureTrans';
import { prop } from '../../utilities';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { defaultSortOption } from '../../components/Category/Category';
import {
  categoryFilterDataSelector,
  urlAttribsToObject,
} from '../Category/categorySlice';
import { categoryEntitiesSelector } from '../Header/selectors';

interface Props {
  user: any;
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
      sort?: string;
      sortDir?: string;
    };
    pathname: string;
  };
  searchedProducts: any;
  searchIsFetching: boolean | true;
  dispatch: (action: any) => void;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  hostname: string;
  params: {
    brand_id: string;
    brand_name: string;
    brandUrl: string;
  };
  brandDetail: ThenArg<typeof API.loadBrandDetail>;
  brandCategories: any;
  brandName: string;
  currency: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  byId: any;
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(0)} ${rem(20)};
  width: 100%;
`;

const Header = styled(FlexCol)`
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class BrandContainer extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string =>
    props.location.query.q;

  public static getBrandId = (props: Props): string => {
    return props.params.brand_id;
  };
  public static getBrandName = (props: Props): string => {
    return props.params.brand_name;
  };
  public static getBrandUrl = (props: Props): string => {
    return props.params.brandUrl;
  };

  public static async getInitialProps(props: Props) {
    try {
      const { dispatch, location } = props;
      const {
        query: { offset, sort, sortDir },
      } = location;

      let brandId = BrandContainer.getBrandId(props);
      let brandUrl = BrandContainer.getBrandUrl(props);

      if (brandId) {
        await Promise.all([
          (await dispatch(loadSearchTermResults('', 20, offset, brandId)),
          await dispatch(loadBrandDetail(brandId, true)),
          await dispatch(loadBrandCategories(brandId))),
        ]);
        dispatch(
          setBreadCrumbPath(BreadCrumbType.BRAND, {
            name: BrandContainer.getBrandName(props),
            url: '',
          }),
        );

        return {
          brandName: BrandContainer.getBrandName(props),
        };
      } else if (brandUrl) {
        const brandsResponse = await API.loadBrands({ url: brandUrl });
        if (brandsResponse?.brands?.length) {
          const brand = brandsResponse.brands[0];
          brandId = brand.brand_id + '';
          const brandName = brand.brand_name;
          const [defaultSort, defaultSortDir] = defaultSortOption.split('-');
          await Promise.all([
            // await dispatch(loadSearchTermArticlesResults(searchTerm, 4)),
            await dispatch(
              loadSearchTermResults(
                '',
                21,
                offset,
                brandId,
                {
                  sort: defaultSort,
                },
                undefined,
                urlAttribsToObject(location.query),
              ),
            ),
            await dispatch(loadBrandDetail(brandId, true)),
            await dispatch(loadBrandCategories(brandId)),
          ]);
          dispatch(
            setBreadCrumbPath(BreadCrumbType.BRAND, {
              name: brandName,
              url: '',
            }),
          );

          return {
            brandName: brandName,
          };
        }
      }

      return {};
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { lang, location, hostname, brandName } = this.props;
    const q = BrandContainer.getSearchQuery(this.props);

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: `${brandName}` }}
            // canonicalUrl={`${resolveBaseUrlByLang(lang)}${location.pathname}`}
          />
          {this.renderResults()}
        </Wrapper>
      </>
    );
  }

  public renderResults() {
    const {
      searchedProducts: products,
      searchIsFetching,
      dispatch,
      user,
      router,
      location,
      articles,
      isFetchingArticles,
      lang,
      hostname,
      brandDetail,
      brandCategories,
      currency,
      filterData,
      byId,
    } = this.props;
    const baseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;
    const searchTerm = BrandContainer.getSearchQuery(this.props);

    const subcategoryFilterAttribsArray: string[] = [];
    if (
      brandDetail &&
      brandDetail.filter_attribs &&
      brandDetail.filter_attribs.length
    ) {
      for (const filterAttrib of brandDetail.filter_attribs) {
        subcategoryFilterAttribsArray.push(
          `${filterAttrib.attrib_id}|${filterAttrib.attrib_value_id}`,
        );
      }
    }
    const subcategoryFilterAttribs = subcategoryFilterAttribsArray.join(',');

    let brandDesc: string = '';
    if (brandDetail) {
      const hasBrandContactInfo: boolean = !!(
        brandDetail.official_name ||
        brandDetail.address ||
        brandDetail.email
      );
      brandDesc = `<div> <div> ${
        brandDetail.brand_descr
      } </div><br><div style="display:${
        hasBrandContactInfo ? 'block' : 'none'
      };"><div><b>${__('Kontaktné informácie výrobcu')}:</b></div><div>${
        brandDetail.official_name
      }</div><div>${brandDetail.address}</div><div>${
        brandDetail.email
      }</div></div></div>`;
    }

    return (
      <SearchResults
        user={user}
        apiUrl={baseApiUrl}
        query={this.getPaginationQuery()}
        dispatch={dispatch}
        isFetching={searchIsFetching}
        products={products}
        addToCart={this.addToCart}
        router={router}
        searchTerm={searchTerm}
        location={location}
        articles={articles}
        isFetchingArticles={isFetchingArticles}
        domainId={+resolveDomainByHostname(hostname)}
        title={brandDetail ? brandDetail.brand_name : undefined}
        description={brandDesc ? brandDesc : undefined}
        titleImage={brandDetail ? brandDetail.brand_image : undefined}
        titleLink={brandDetail ? brandDetail.brand_provider : undefined}
        categories={
          brandCategories && brandCategories.length
            ? brandCategories
            : undefined
        }
        subcategoryFilterAttribs={subcategoryFilterAttribs}
        categoryAttribs={products.attribs}
        currency={currency}
        filterData={filterData}
        lang={lang}
        byId={byId}
      />
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count));
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  searchIsFetching: searchResultsIsFetchingSelector(state),
  searchedProducts: searchResultsSelector(state),
  lang: langSelector(state),
  articles: searchResultsArticlesSelector(state),
  isFetchingArticles: searchResultsIsFetchingArticlesSelector(state),
  hostname: hostnameSelector(state),
  currency: currencySelector(state),
  filterData: categoryFilterDataSelector(state),
  brandDetail: brandDetailSelector(state),
  brandCategories: brandCategoriesSelector(state),
  byId: categoryEntitiesSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'BrandContainer' })(withRouter(BrandContainer)),
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import APIClass, { ThenArg } from '../../services/API';
import { startFetching, stopFetching } from '../../utilities/redux';
import { AppThunk } from '../../rootReducer';
import { strictEqual } from 'assert';
import { getImagePath } from '../../utilities/product';
import { hostnameSelector, langSelector } from '../App/selectors';
import { resolveDomainByHostname } from '../../configureTrans';
import { __r } from 'react-i18n';

export interface ActionItemInterface {
  endedDate: Date;
  title: string;
  description: string;
  imageUrl: string;
  url: string;
}

interface ActionsState {
  jblActions: {
    data: Array<ActionItemInterface> | null;
    isFetching: boolean;
  };
}

const initialState: ActionsState = {
  jblActions: {
    data: [],
    isFetching: false,
  },
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    startFethingActions(state) {
      startFetching(state, 'jblActions');
    },

    setActions(
      state,
      action: PayloadAction<{
        actions: Array<ActionItemInterface>;
      }>,
    ) {
      stopFetching(state, 'jblActions');
      state.jblActions.data = action.payload.actions;
    },

    stopFetchingActions(state) {
      stopFetching(state, 'jblActions');
    },
  },
});

export const {
  startFethingActions,
  setActions,
  stopFetchingActions,
} = actionsSlice.actions;

export default actionsSlice.reducer;

export const fetchActions = (): AppThunk => async (dispatch, getState, API) => {
  try {
    dispatch(startFethingActions());
    const lang = langSelector(getState());
    const hostname = hostnameSelector(getState());

    const actions: /*ThenArg<typeof APIClass.loadLandingPages>*/ any = await API.loadLandingPages(
      {
        withContent: 1,
        lang,
        productOffset: 0,
        productLimit: 10,
        limit: 100,
        domainId: [+resolveDomainByHostname(hostname)],
        showInList: true,
      },
    );

    // filter empty actions
    const categoryResults: {
      webLandingPageId: number;
      categoryProducts: any;
    }[] = await Promise.all(
      actions.web_landing_pages.map(async webLandingPage => {
        const categoryProducts = await API.loadElasticCategoryProducts(-1, {
          limit: 1,
          domainId: resolveDomainByHostname(hostname),
          langId: lang,
          onlySubcategories: '1',
          webContentIds: [webLandingPage.descrContentId],
          onlyWithoutParent: webLandingPage.includeRecycled ? undefined : '1',
        });

        return { webLandingPageId: webLandingPage.id, categoryProducts };
      }),
    );

    const webLandingPages = actions.web_landing_pages.filter(webLandingPage => {
      const categoryResult = categoryResults.find(
        categoryResultx =>
          categoryResultx.webLandingPageId === webLandingPage.id,
      );
      if (categoryResult?.categoryProducts?.products?.total === 0) {
        return false;
      }

      return true;
    });

    dispatch(
      setActions({
        actions: webLandingPages.map(action => {
          return {
            endedDate: action.dateTo ? new Date(action.dateTo) : null,
            dateFromReadable: action.dateFromReadable,
            dateToReadable: action.dateToReadable,
            isUpcoming: action.isUpcoming,
            title: action.name ? action.name : '',
            description: action.content
              ? action.content.json_content.anotation
              : '',
            imageUrl:
              action.content &&
              action.content.photogallery &&
              action.content.photogallery.length
                ? getImagePath(
                    action.content.photogallery[0].document_path,
                    {
                      width: 704,
                      height: 225,
                    },
                    false,
                    true,
                    true,
                  )
                : null,
            url: `/${action.url.replace(/^\/|\/$/g, '')}`,
          };
        }),
      }),
    );
  } catch (e) {
    dispatch(stopFetchingActions());
  }
};

const actionsDomainSelector = state => state.actions;

export const actionsSelector = createSelector(actionsDomainSelector, substate =>
  prop(substate, 'jblActions.data', []),
);

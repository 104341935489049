import * as cookies from 'react-cookies';
import {
  CURRENCY_COOKIE,
  LANG_COOKIE,
  SET_CURRENCY,
  SET_DEFAULT_TITLE,
  SET_LANGUAGE,
  SET_DOMAIN_ID,
  SET_FOOTER_DATA,
  SET_CONTACT_INFO,
  SET_SOCIAL_URLS,
  SET_META_TAGS,
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_SUCCESS,
  RECEIVE_REGISTER_USER_FAILURE,
  OPEN_DIMMER,
  CLOSE_DIMMER,
  REQUEST_CREATED_ORDER,
  RECEIVE_CREATED_ORDER_SUCCESS,
  RECEIVE_CREATED_ORDER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SET_INVOICE_DATA,
  SET_CONTROL_INSTITUTE_DATA,
  SET_POINTS_AMOUNT_RATIO,
  SET_FREE_DELIVERY_INFO,
  SET_TOP_MENU_BRANDS,
  REQUEST_SEARCH_TERM,
  RECEIVE_SEARCH_TERM_SUCCESS,
  RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  REQUEST_PARTIAL_SEARCH_TERM,
  SET_SEARCH_RESULTS_FILTERS,
  ERROR_TOP_MENU_ITEMS,
  REQUEST_TOP_MENU_ITEMS,
  REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  SET_CURRENT_THEME,
  SET_SETTINGS_LOADED,
  SET_HEADER_DATA,
  SET_BRAND_IDS,
  SET_LANGUAGE_CHANGED,
  SET_BREADCRUMB_VISIBLE,
  REQUEST_PARTIAL_ARTICLES_SEARCH_TERM,
  RECEIVE_PARTIAL_ARTICLES_SEARCH_TERM_SUCCESS,
  REQUEST_SEARCH_TERM_ARTICLES,
  RECEIVE_SEARCH_TERM_ARTICLES_SUCCESS,
  SET_NOT_FOUND,
  SET_HOSTNAME,
  SET_OTHER_TEXTS,
  SET_GENERAL_SETTINGS,
  RECEIVE_BRAND_DETAIL_SUCCESS,
  RECEIVE_BRAND_CATEGORIES_SUCCESS,
  SET_DEFAULT_HOME_TITLE,
  ADMIN_CONTENT_PATH,
  SET_ECOMAIL_SETTINGS,
  // REQUEST_ADD_TO_FAVORITES,
} from './constants';
import { generalInitialState } from './general-reducer';
import { cookiesExpiresDate, prop, redirect } from '../../utilities';
import {
  currencySelector,
  hostnameSelector,
  langSelector,
  searchResultsIsFetchingArticlesSelector,
  searchResultsIsFetchingSelector,
} from './selectors';
import API from '../../services/API';
import { productCategoriesSelector } from '../Header/selectors';
import {
  DEFAULT_CATEGORY_ID,
  DEFAULT_SITEMAP_ID,
  resolveBaseUrlByLang,
  resolveDomainByHostname,
  resolveLangByHostname,
  resolveTagByHostname,
} from '../../configureTrans';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { mapCategoriesTreeByIdRecursive } from '../Header/reducer';
import { resolveArticleContentDetails } from '../../utilities/article';
import { defaultSortOption } from '../../components/Category/Category';
import { cartIdSelector } from '../Cart/cartSlice';
import { __r } from 'react-i18n';

export const setLanguage = lang => {
  // const fromCookie = cookies.load(LANG_COOKIE);
  // if (fromCookie) {
  //   lang = fromCookie;
  // } else if (!lang) {
  //   lang = generalInitialState.lang;
  // }

  // cookies.save(LANG_COOKIE, lang, { path: '/', expires: cookiesExpiresDate() });

  return {
    type: SET_LANGUAGE,
    payload: {
      lang,
    },
  };
};

export const setDomainId = domainId => {
  return {
    type: SET_DOMAIN_ID,
    payload: {
      domainId,
    },
  };
};

export const setLanguageWasChanged = () => {
  return {
    type: SET_LANGUAGE_CHANGED,
  };
};

export const setHostname = (hostname: string) => {
  return {
    type: SET_HOSTNAME,
    payload: {
      hostname,
    },
  };
};

export const changeLanguage = newLang => {
  cookies.save(LANG_COOKIE, newLang, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_LANGUAGE,
    payload: {
      lang: newLang,
    },
  };
};

export const setCurrency = currency => {
  // if (!currency) {
  //   currency = generalInitialState.currency;
  // }
  // cookies.save(CURRENCY_COOKIE, currency, {
  //   path: '/',
  //   expires: cookiesExpiresDate(),
  // });
  return {
    type: SET_CURRENCY,
    payload: {
      currency,
    },
  };
};

export const setDefaultMetaTitle = defaultTitle => {
  return {
    type: SET_DEFAULT_TITLE,
    payload: {
      defaultTitle,
    },
  };
};

export const setDefaultMetaHomeTitle = defaultHomeTitle => {
  return {
    type: SET_DEFAULT_HOME_TITLE,
    payload: {
      defaultHomeTitle,
    },
  };
};

export const loadDefaultMetaTitle = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const [{ value: defaultTitle }] = await API.getSettingByName(
        'meta_title',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      dispatch(setDefaultMetaTitle(defaultTitle));
    } catch (e) {
      console.log(e);
    }
  };
};

export const requestTopMenuItems = () => {
  return {
    type: REQUEST_TOP_MENU_ITEMS,
  };
};

export const errorTopMenuItems = () => {
  return {
    type: ERROR_TOP_MENU_ITEMS,
  };
};

export const setTopMenuItems = headerData => {
  return {
    type: SET_HEADER_DATA,
    payload: {
      headerData,
    },
  };
};

export const loadTopMenuItems = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestTopMenuItems());
      const currentState = getState();
      const hostname = hostnameSelector(currentState);

      const headerData = await API.loadSitemapSubtree(
        `${resolveTagByHostname(hostname)}_HEADER`,
        {},
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      dispatch(setTopMenuItems(headerData));
    } catch (e) {
      console.log(e);
      dispatch(errorTopMenuItems());
    }
  };
};

export const setTopMenuBrands = brands => {
  return {
    type: SET_TOP_MENU_BRANDS,
    payload: {
      brands,
    },
  };
};

export const loadTopMenuBrands = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const brands = await API.loadBrands(
        { sort: 'brand_name', sortDir: 'asc' },
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      const topMenuBrands = brands.brands;

      dispatch(setTopMenuBrands(topMenuBrands));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setFooterData = footerData => {
  return {
    type: SET_FOOTER_DATA,
    payload: {
      footerData,
    },
  };
};

export const loadFooterData = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      if (!currentState.general.footerData) {
        const lang = langSelector(currentState);
        const hostname = hostnameSelector(currentState);
        const footerData = await API.loadSitemapSubtree(
          `${resolveTagByHostname(hostname)}-SPODNE-MENU`,
          { withoutContent: '1' },
          { xAcceptLanguage: lang },
        );

        dispatch(setFooterData(footerData));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const setContactInfo = contactInfo => {
  return {
    type: SET_CONTACT_INFO,
    payload: {
      contactInfo,
    },
  };
};

export const loadContactInfo = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let contactInfo = await API.getSettingByName('email,tel, ', {
        xAcceptLanguage: currentState.general.lang,
      });

      const [{ value: contactEmail }, { value: contactTel }] = contactInfo;

      contactInfo = {
        contactEmail,
        contactTel,
      };

      dispatch(setContactInfo(contactInfo));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setSocialUrls = socialUrls => {
  return {
    type: SET_SOCIAL_URLS,
    payload: {
      socialUrls,
    },
  };
};

export const loadSocialUrls = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let socialUrls = await API.getSettingByName(
        'socials_facebook,socials_instagram,socials_youtube,socials_blog,heureka_url',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      const [
        { value: fbUrl },
        { value: igUrl },
        { value: partyBlog },
        { value: heurekaUrl },
      ] = socialUrls;

      socialUrls = {
        fbUrl,
        igUrl,
        partyBlog,
        heurekaUrl,
      };

      dispatch(setSocialUrls(socialUrls));
    } catch (e) {
      console.log(e);
    }
  };
};

const setSettingsLoaded = (isLoaded: boolean) => {
  return {
    type: SET_SETTINGS_LOADED,
    payload: {
      isLoaded,
    },
  };
};

export const setMetaTags = metaTags => {
  return {
    type: SET_META_TAGS,
    payload: {
      metaTags,
    },
  };
};

export const setBrandIds = brandIds => {
  return {
    type: SET_BRAND_IDS,
    payload: {
      brandIds,
    },
  };
};

export const loadMetaTags = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let metaTags = await API.getSettingByName(
        'meta_description,meta_author,meta_keywords',
        {
          xAccpetLanguage: currentState.general.lang,
        },
      );

      const [
        { value: metaDescription },
        { value: metaKeywords },
        { value: metaAuthor },
      ] = metaTags;

      metaTags = {
        metaDescription,
        metaKeywords,
        metaAuthor,
      };

      dispatch(setMetaTags(metaTags));
    } catch (e) {
      console.log(e);
    }
  };
};

export const showDimmer = (lockScrollBar: boolean = true) => ({
  type: OPEN_DIMMER,
  payload: {
    lockScrollBar,
  },
});

export const hideDimmer = () => ({
  type: CLOSE_DIMMER,
});

const requestCreatedOrder = () => ({
  type: REQUEST_CREATED_ORDER,
});

const receivecreatedOrderSuccess = createdOrder => ({
  type: RECEIVE_CREATED_ORDER_SUCCESS,
  payload: {
    createdOrder,
  },
});

const receiveCreatedOrderError = error => ({
  type: RECEIVE_CREATED_ORDER_ERROR,
  payload: {
    error,
  },
});

export const loadCreatedOrder = (createdOrderId: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCreatedOrder());
      const order = await API.getOrder(createdOrderId);
      dispatch(receivecreatedOrderSuccess(order));
    } catch (e) {
      dispatch(receiveCreatedOrderError(e));
    }
  };
};

export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL,
});

export const hideLoginModal = () => ({
  type: CLOSE_LOGIN_MODAL,
});

const requestRegisterUser = () => ({
  type: REQUEST_REGISTER_USER,
});

const registerUserSuccess = () => ({
  type: RECEIVE_REGISTER_USER_SUCCESS,
});

const registerUserError = error => ({
  type: RECEIVE_REGISTER_USER_FAILURE,
  payload: {
    error,
  },
});

export const registerUser = (
  email: string,
  password: string,
  confirmPassword: string,
  termsAccept: boolean,
  newsletterAccept: boolean = false,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestRegisterUser());
      const lang: string = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const response = await API.registerUser(
        {},
        {
          email,
          lang,
          password,
          password_again: confirmPassword,
          terms_accept: termsAccept,
          newsletter_accept: newsletterAccept,
          domain_id: resolveDomainByHostname(hostname),
          scope: 'all',
          app_id: 'eshop',
        },
      );
      dispatch(registerUserSuccess());

      if (response?.access_token) {
        const baseUrl = resolveBaseUrlByLang(hostname, lang);
        let route = __r('routes:prihlasenie', '/prihlasenie');
        if (route.charAt(0) === '/') route = route.substring(1);

        redirect(
          `${baseUrl}${route}?#register=1&access_token=${response?.access_token}`,
        );
      }
    } catch (e) {
      dispatch(registerUserError(e.details.description));
    }
  };
};

export const setInvoiceData = invoiceData => ({
  type: SET_INVOICE_DATA,
  payload: {
    invoiceData,
  },
});

export const setControlInstituteData = controlInstituteData => ({
  type: SET_CONTROL_INSTITUTE_DATA,
  payload: {
    controlInstituteData,
  },
});

export const setPointsAmountRatio = pointsAmountRatio => ({
  type: SET_POINTS_AMOUNT_RATIO,
  payload: {
    pointsAmountRatio,
  },
});

export const setFreeDeliveryInfo = freeDeliveryInfo => ({
  type: SET_FREE_DELIVERY_INFO,
  payload: {
    freeDeliveryInfo,
  },
});

export const resetToken = () => {
  return async (dispatch, getState, API) => {
    API.setToken('');
  };
};

export const setGeneralSettings = settings => {
  return {
    type: SET_GENERAL_SETTINGS,
    payload: {
      settings,
    },
  };
};

export const setEcomailSettings = ecomailSettings => ({
  type: SET_ECOMAIL_SETTINGS,
  payload: {
    ecomailSettings,
  },
});

export const loadDefaultSettings = (hostname: string) => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();

      //if (!currentState.general.areSettingsLoaded) {
      const lang = langSelector(getState());
      // const currency = getState().general.currency;

      // const freeDeliveryInfo = await API.getGeneralFreeDeliveryInfo(
      //   {},
      //   { xAcceptLanguage: lang, xCurrency: currency },
      // );

      // dispatch(setFreeDeliveryInfo(freeDeliveryInfo));

      const randomNumber = Math.floor(Math.random() * 100000);

      const data = await API.getSettingByName(
        `meta_title,meta_homeTitle,infoEmail,infoPhone,meta_description,meta_keywords,meta_author,meta_og_title,meta_og_description,meta_og_type,meta_og_image,socials_instagram,socials_facebook,socials_youtube,socials_tiktok,homepageSlideInterval,Ecomail_customScript,${randomNumber}`,
        { domainId: resolveDomainByHostname(hostname) },
        {
          xAcceptLanguage: resolveLangByHostname(hostname),
        },
      );

      let socialUrls: any = {};
      let metaTags: any = {};
      let ecomailSettings: any = {};

      data.map(d => {
        if (d.name === 'meta_title') {
          dispatch(setDefaultMetaTitle(d.value));
        } else if (d.name === 'meta_homeTitle') {
          dispatch(setDefaultMetaHomeTitle(d.value));
        } else if (d.name === 'meta_keywords') {
          metaTags.metaKeywords = d.value;
        } else if (d.name === 'meta_description') {
          metaTags.metaDescription = d.value;
        } else if (d.name === 'meta_author') {
          metaTags.metaAuthor = d.value;
        } else if (d.name === 'meta_og_image') {
          metaTags['og:image'] = d.value;
        } else if (d.name === 'meta_og_title') {
          metaTags['og:title'] = d.value;
        } else if (d.name === 'meta_og_description') {
          metaTags['og:description'] = d.value;
        } else if (d.name === 'meta_og_type') {
          metaTags['og:type'] = d.value;
        } else if (d.name === 'socials_youtube') {
          socialUrls.youtubeUrl = d.value;
        } else if (d.name === 'socials_instagram') {
          socialUrls.instagramUrl = d.value;
        } else if (d.name === 'socials_facebook') {
          socialUrls.fbUrl = d.value;
        } else if (d.name === 'socials_tiktok') {
          socialUrls.tiktokUrl = d.value;
        } else if (
          d.name === 'Ecomail_customScript' &&
          typeof d.value === 'string'
        ) {
          ecomailSettings.customScript = encodeURIComponent(
            d.value.replace(/^<script>\s*|\s*<\/script>$/g, ''),
          );
        }
      });

      if (metaTags) {
        dispatch(setMetaTags(metaTags));
      }

      if (socialUrls) {
        dispatch(setSocialUrls(socialUrls));
      }

      if (ecomailSettings) {
        dispatch(setEcomailSettings(ecomailSettings));
      }

      // if (domainBrands) {
      //   const brandIds = domainBrands.map(b => b.brand_id);
      //   dispatch(setBrandIds(brandIds));
      // }

      const settings = {
        homepageSlideInterval: data.find(
          d => d.name === 'homepageSlideInterval',
        )?.value,
      };

      dispatch(setGeneralSettings(settings));
      dispatch(setSettingsLoaded(true));
      //}
    } catch (e) {
      console.log(e);
    }
  };
};

const requestSearchTerm = (searchTerm: string) => ({
  type: REQUEST_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receiveSearchTermResultsSuccess = (searchTerm, products) => ({
  type: RECEIVE_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadSearchTermResults = (
  searchTerm: string,
  limit: number = 20,
  offset: number = 0,
  brandId: string = '',
  { sort, sortDir }: { sort?: string; sortDir?: string } = {
    sort: '',
    sortDir: '',
  },
  tagId?: number,
  filters: any = {},
) => {
  return async (dispatch, getState, API) => {
    try {
      // if (!searchResultsIsFetchingSelector(getState())) {
      dispatch(requestSearchTerm(searchTerm));
      dispatch(setSearchResultsFilters({ offset }));

      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const [defaultSort, defaultSortDir] = defaultSortOption.split('-');
      const hostname = hostnameSelector(getState());
      const cartId = cartIdSelector(getState());

      const searchObject: any = {
        limit,
        q: searchTerm,
        offset,
        withAttribs: '0',
        withGifts: '0',
        withBrand: '0',
        categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
        withPublish: '1',
        langId: lang,
        onlySubcategories: '1',
        domainId: resolveDomainByHostname(hostname),
        cartId,
        sort: sort,
        ...filters,
        ...filters.otherAttribs,
      };

      // searchObject.sort = sort || defaultSort;
      // searchObject.sortDir = sortDir || defaultSortDir;

      if (tagId) {
        searchObject.tagIds = tagId;
      }

      if (brandId) {
        searchObject.brandId = brandId;
      }

      const elasticCategory = await API.loadElasticCategoryProducts(
        DEFAULT_CATEGORY_ID[hostname][lang],
        searchObject,
      );

      dispatch(
        setBreadCrumbPath(BreadCrumbType.SEARCH, {
          name: searchTerm,
          url: '',
        }),
      );

      dispatch(
        receiveSearchTermResultsSuccess(searchTerm, {
          ...elasticCategory.products,
          price_min: elasticCategory.minPrice,
          price_max: elasticCategory.maxPrice,
          includesGifts: elasticCategory.includesGifts,
          includesSales: elasticCategory.includesSales,
          includesRecycled: elasticCategory.includesRecycled,
          includesFreeDelivery: elasticCategory.includesFreeDelivery,
          attribs: elasticCategory.attribsAggs,
          includesNew: elasticCategory.includesNew,
        }),
      );
      // }
    } catch (e) {
      console.error(e);
    }
  };
};

const requestSearchTermArticles = (searchTerm: string) => ({
  type: REQUEST_SEARCH_TERM_ARTICLES,
  payload: {
    searchTerm,
  },
});

const receiveSearchTermArticlesResultsSuccess = (searchTerm, articles) => ({
  type: RECEIVE_SEARCH_TERM_ARTICLES_SUCCESS,
  payload: {
    articles,
    searchTerm,
  },
});

export const loadSearchTermArticlesResults = (
  searchTerm: string,
  limit: number = 4,
  tagId?: number,
) => {
  return async (dispatch, getState, API) => {
    try {
      const savedSearchTerm = getState().general.search.articles.searchTerm;
      if (!searchResultsIsFetchingArticlesSelector(getState())) {
        dispatch(requestSearchTermArticles(searchTerm));

        const lang = langSelector(getState());
        const hostname = hostnameSelector(getState());
        let articles = null;

        if (tagId) {
          articles = await API.loadArticles(
            {
              limit,
              isPublished: '1',
              sitemapId: DEFAULT_SITEMAP_ID[hostname][lang],
              query: searchTerm,
              sfForm: 'Article',
              onlyActual: '1',
              tagId: tagId ? tagId : undefined,
              sort: 'created_date',
            },
            { xAcceptLanguage: lang },
          );
        } else {
          articles = await API.loadElasticArticles({
            limit,
            langId: lang,
            domainId: resolveDomainByHostname(hostname),
            q: searchTerm,
            productsDomainId: resolveDomainByHostname(hostname),
          });
        }

        dispatch(receiveSearchTermArticlesResultsSuccess(searchTerm, articles));
      }
    } catch (e) {
      dispatch(receiveSearchTermArticlesResultsSuccess(searchTerm, null));
      console.error(e);
    }
  };
};

export const loadSitemap = lang => {
  return async (dispatch, getState, API) => {
    try {
      // const content = await API.getHtmlSitemap(lang);
      // await dispatch(receiveSitemapSuccess(content));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setSearchResultsFilters = filters => ({
  type: SET_SEARCH_RESULTS_FILTERS,
  payload: {
    filters,
  },
});

const requestPartialSearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialSearchTermResultsSuccess = (searchTerm, products) => ({
  type: RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadPartialSearchTermResults = (
  searchTerm: string,
  limit: number = 4,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialSearchTerm(searchTerm));
      const lang: string = langSelector(getState());
      const currency = currencySelector(getState());
      const hostname = hostnameSelector(getState());
      const cartId = cartIdSelector(getState());
      const products = await API.loadElasticCategoryProducts(-1, {
        limit,
        q: searchTerm,
        withAttribs: '0',
        withGifts: '0',
        withBrand: '0',
        categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
        withPublish: '1',
        langId: lang,
        onlySubcategories: '1',
        domainId: resolveDomainByHostname(hostname),
        cartId,
      });
      // const products = await API.searchProducts(
      //   {
      //     limit,
      //     q: searchTerm,
      //     withAttribs: '0',
      //     withGifts: '0',
      //     withBrand: '0',
      //     categoryId: DEFAULT_CATEGORY_ID[lang],
      //   },
      //   { xAcceptLanguage: lang, xCurrency: currency },
      // );

      dispatch(receivePartialSearchTermResultsSuccess(searchTerm, products));
    } catch (e) {
      console.error(e);
    }
  };
};

const requestPartialCategorySearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialCategorySearchTermResultsSuccess = (
  searchTerm,
  products,
) => ({
  type: RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  payload: {
    products,
    searchTerm,
  },
});

export const loadPartialCategorySearchTermResults = (searchTerm: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialCategorySearchTerm(searchTerm));
      // this is special case, when we have only one tree in sk version
      const publishLang: string = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const lang = 'sk';

      const categories = await API.loadCategories(
        {
          q: searchTerm,
          categoryTopParentId: DEFAULT_CATEGORY_ID[hostname][lang],
          withPublish: '1',
          publishLang,
        },
        { xAcceptLanguage: lang },
      );
      const productCategories = productCategoriesSelector(getState());
      const categoriesById = mapCategoriesTreeByIdRecursive(productCategories);
      const categoriesWithAtLeastOneProduct = categories
        .map(category => ({
          ...category,
          counts: prop(categoriesById[category.category_id], 'counts', 0),
        }))
        .filter(c => c.counts > 0)
        .slice(0, 2);
      dispatch(
        receivePartialCategorySearchTermResultsSuccess(
          searchTerm,
          categoriesWithAtLeastOneProduct,
        ),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

const requestPartialArticlesSearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_ARTICLES_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialArticlesSearchTermResultsSuccess = (
  searchTerm,
  articles,
) => ({
  type: RECEIVE_PARTIAL_ARTICLES_SEARCH_TERM_SUCCESS,
  payload: {
    articles,
    searchTerm,
  },
});

export const loadPartialArticlesSearchTermResults = (searchTerm: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialArticlesSearchTerm(searchTerm));
      const lang: string = langSelector(getState());

      // const articles = await API.loadArticles(
      //   {
      //     limit: 2,
      //     isPublished: '1',
      //     sitemapId: DEFAULT_SITEMAP_ID[lang],
      //     query: searchTerm,
      //     sfForm: 'Article',
      //     onlyActual: '1',
      //     tagId: 1020,
      //   },
      //   { xAcceptLanguage: lang },
      // );

      const hostname = hostnameSelector(getState());

      const articles = await API.loadElasticArticles({
        limit: 2,
        langId: lang,
        domainId: resolveDomainByHostname(hostname),
        q: searchTerm,
        productsDomainId: resolveDomainByHostname(hostname),
      });

      dispatch(
        receivePartialArticlesSearchTermResultsSuccess(searchTerm, articles),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const setCurrentTheme = theme => ({
  type: SET_CURRENT_THEME,
  payload: {
    theme,
  },
});

// const requestAddToFavorites = () => ({
//   type: REQUEST_ADD_TO_FAVORITES,
// });

export const addProductToFavorites = (customer_id, product_id) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(await API.favoriteProduct(customer_id, product_id));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setIsBreadcrumbVisible = isVisible => ({
  type: SET_BREADCRUMB_VISIBLE,
  payload: {
    isVisible,
  },
});

export const setNotFound = notFound => {
  return {
    type: SET_NOT_FOUND,
    payload: {
      notFound,
    },
  };
};

export const loadOtherDefaultsTexts = () => {
  return async (dispatch, getState, API) => {
    const lang: string = langSelector(getState());
    const hostname: string = hostnameSelector(getState());

    try {
      const otherTexts = {};
      const uniqIds = ['TOP_INFORMATION_STRIP'];
      for (const uniqId of uniqIds) {
        const loadOtherTextsResult = await API.loadOtherTexts(uniqId, {
          langId: lang,
          domainId: resolveDomainByHostname(hostname),
          fallbackDomainId: 0,
        });
        if (loadOtherTextsResult && loadOtherTextsResult.content) {
          if (!loadOtherTextsResult.content.draft) {
            const content = resolveArticleContentDetails(
              loadOtherTextsResult.content,
            );
            if (content && content.body) {
              otherTexts[uniqId] = {
                body: content.body,
              };
            }
          }
        }
      }
      dispatch(setOtherTexts(otherTexts));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setOtherTexts = otherTexts => {
  return {
    type: SET_OTHER_TEXTS,
    payload: {
      otherTexts,
    },
  };
};

const receiveBrandDetailSuccess = brandDetail => ({
  type: RECEIVE_BRAND_DETAIL_SUCCESS,
  payload: {
    brandDetail,
  },
});

export const loadBrandDetail = (brandId, replaceWithTrans) => {
  return async (dispatch, getState, API) => {
    const currentState = getState();

    const brandDetail = await API.loadBrandDetail(
      brandId,
      { withBrandFilters: true },
      {
        xAcceptLanguage: currentState.general.lang,
      },
    );

    if (brandDetail && replaceWithTrans) {
      const currentTrans = brandDetail?.translations?.length
        ? brandDetail.translations.find(
            trans => trans.lang_id === currentState.general.lang,
          )
        : null;
      if (currentTrans) {
        brandDetail.brand_name = currentTrans.brand_name
          ? currentTrans.brand_name
          : brandDetail.brand_name;
        brandDetail.brand_descr = currentTrans.brand_descr
          ? currentTrans.brand_descr
          : brandDetail.brand_descr;
      }
    }

    if (brandDetail) {
      dispatch(receiveBrandDetailSuccess(brandDetail));
    }
  };
};

const receiveBrandCategoriesSuccess = brandCategories => ({
  type: RECEIVE_BRAND_CATEGORIES_SUCCESS,
  payload: {
    brandCategories,
  },
});

export const loadBrandCategories = brandId => {
  return async (dispatch, getState, API) => {
    const currentState = getState();
    const hostname = hostnameSelector(getState());
    const lang: string = langSelector(getState());

    const brandCategories = await API.loadBrandCategories(
      brandId,
      { limit: 12, categoryTopParentId: DEFAULT_CATEGORY_ID[hostname][lang] },
      {
        xAcceptLanguage: currentState.general.lang,
      },
    );

    if (brandCategories) {
      dispatch(receiveBrandCategoriesSuccess(brandCategories.catalog_category));
    }
  };
};

export const setAdminContentPath = adminContentPath => {
  return async (dispatch, getState, API) => {
    dispatch(reduxContentPath(adminContentPath));
  };
};

const reduxContentPath = adminContentPath => ({
  type: ADMIN_CONTENT_PATH,
  payload: {
    adminContentPath,
  },
});
